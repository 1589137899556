import * as React from 'react';
import Layout from "../../Components/Layout"
import { FooterComponent } from '../../Components/FooterComponent';
import SupportIndex from '../../modules/Support/SupportIndex';
import HeaderComponent from '../../Components/HeaderComponent';
import Seo from '../../Components/Seo';

const indexPage = () => {
  return (
    <>
      <Seo title="Support | Webbermill"/>
      <Layout name="support"  >
        <title>Support Page</title>
        <HeaderComponent hasProduct/>
        <SupportIndex  />
        <FooterComponent />
      </Layout>
    </>
  );
};

export default indexPage;