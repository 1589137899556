import { Form } from "react-bootstrap"
import { AiOutlineSearch } from "react-icons/ai"
import * as React from 'react'

export const SearchComponent = () => {
    return(
      <>
      <form>
          <div className='row'>
              <div className="col has_search">
              <AiOutlineSearch className="search" />
              <Form.Control size="lg" placeholder="Search" />
              </div>
          </div>
          </form>
      </>
    )
  }