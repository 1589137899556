import { Link } from 'gatsby';
import * as React from 'react'
import styled from 'styled-components';
import { WebbermillIcon } from '../../Components/SVGs';
import { Questions } from '../../constant/constants';
import { SUPPORT_PRODUCT_PAGE } from '../../constant/routes';
import { SearchComponent } from './components/Search';


const CardWrapper = styled.div`
width: 100%;
height: 200px;
background: #FFFFFF !important;
box-shadow: -2px 7px 9px #0000002B;
border-radius: 121px;
opacity: 1;
display:flex;
cursor: pointer;
z-index: 3;

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 280px) and (max-device-width: 767px) {
  height: 100px;
}



`

const Text = styled.p`
margin: auto;
font: normal normal normal 16px/50px Poppins;
color: #24252B;
opacity: 0.7;

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 280px) and (max-device-width: 767px) {
  font: normal normal normal 12px/50px Poppins;
}
`



const CustomCard = ({text, url}) => {
  return(
    <Link to={url}>
    <CardWrapper>
       <Text>{text}</Text>
    </CardWrapper>
    </Link>
  )
}

const SupportIndex = () => {
  return (
    <section className="support__content">
                <div className='container'>
                    <div className="support__banner-1">
                      <div className='support__banner-1__text'>
                         <h5>Webbermill</h5>
                         <h5>Support Centre</h5>
                      </div>
                      <div className="support__banner-1_search d-none">
                          <SearchComponent />
                      </div>
                    </div>
                    <div className="support__banner-2">
                           <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-4'>
                            <CustomCard text="I want to know more about.." url={SUPPORT_PRODUCT_PAGE}/>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-4'>
                            <CustomCard text="I'm having trouble with....." url={SUPPORT_PRODUCT_PAGE}/>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-4'>
                            <CustomCard text="Contact support desk" url={SUPPORT_PRODUCT_PAGE}/>
                            </div>
                           </div>
                           {/* <img src='banner/webbermill.svg' alt='webbermill'/> */}
                           <div className='icon-svg'>
                             <WebbermillIcon />
                           </div>
                    </div>
                    <div className='support__banner-faq'>
                      <div className='heading'>
                      <h5>Frequently Asked Questions</h5>
                            <p>Get all the tips and headsupand more from tewaa guide</p>
                      </div>
                      <div className='cards-content'>
                      {
                        Questions.map((item, index) => (
                          <div className='card' key={index}>
                          <h5>{item.title}</h5>
                          <p>{item.text}</p>
                        </div>
                        ))
                      }
                      
                      </div>
                    </div>
                </div>
               
            </section>
  )
}

export default SupportIndex